$blockquoteBackgroundColor: #C8C8C8;
$blockquoteBorderColor: #777;
$captionColor: #757575;
$contentBackgroundColor: #FAFAF8;
$darkTextColor: black;
$headerBackgroundColor: #111;
$footerBackgroundColor: $headerBackgroundColor;
$lightTextColor: white;
$linkActiveColor: darkred;
$linkColor: red;
$linkHoverColor: pink;
$logoRed: red;
$siteBackgroundColor: #444;

:root {
	--footer-height: 18px;
	--footer-padding: 15px;
}

* {
	position: relative;
}

body {
	background-color: $siteBackgroundColor;
	margin: 0;
	min-height: calc(100vh - var(--footer-height) - var(--footer-padding) * 2);
	padding-bottom: calc(var(--footer-height) + var(--footer-padding) * 2);
}

header {
	background-color: $headerBackgroundColor;
	box-shadow: 0 3px 6px 0 rgba(0,0,0,0.3);
	color: $lightTextColor;
	padding: 20px 0;
	text-align: center;
}

header, .logo, footer {
	font-family: 'Lato', sans-serif !important;
}

.logo {
	letter-spacing: -1.5px;
	font-weight: 400;
}

.logo a {
	color: $lightTextColor;
	text-decoration: none;
}

.logo a:hover {
	text-decoration: underline;
}

.logo-100 {
	filter: grayscale(100%) sepia(100%) hue-rotate(312deg) saturate(12);
}

.logo-100-percent {
	color: $logoRed;
	display: inline-block;
	font-family: "Comic Sans MS", "Comic Sans", cursive !important;
	font-weight: bold;
	transform: rotate(-20deg) scale(1.5);
	z-index: 10;
}

.logo-percent {
	margin-left: -5px;
	font-size: 85%;
	padding-bottom: 1ch;
	vertical-align: text-top;
}

.logo-definitely {
	font-style: italic;
}

.logo-true::before, .logo-true::after {
	content: "\"";
}

.logo-facts {
	font-weight: 900;
	text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'IBM Plex Serif', serif;
	margin: 0;
}

h1 {
	font-size: 50px;
	margin-bottom: 25px;
}

h2 {
	margin-bottom: 3px;
}

main {
	margin: auto;
	max-width: 700px;
	padding: 10px;
}

nav, section, article, aside {
	background-color: $contentBackgroundColor;
	box-shadow: 0 3px 6px 0 rgba(0,0,0,0.32);
	font-family: 'Old Standard TT', serif;
}

section, article {
	padding: 10px;
	margin-bottom: 10px;
}

article>:first-child {
	margin-bottom: 10px;
}

blockquote {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAECAYAAACzzX7wAAAAHklEQVQImWNggIIDBw78Z0ACKHwYB10RYZ24ALKJAK/aGfk1dwPDAAAAAElFTkSuQmCC) repeat;
	background-color: $blockquoteBackgroundColor;
	$blockquoteBorder: 3px $blockquoteBorderColor solid;
	border-bottom: $blockquoteBorder;
	border-top: $blockquoteBorder;
	box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
	margin: 6px 20px;
	padding: 6px 20px;
}

p {
	margin: 0;
}

p:not(:last-child),
li:not(:last-child) {
	margin: 0 0 10px;
}

article img {
	width: 100%;
}

figcaption {
	text-align: center;
	color: $captionColor;
}

a {
	color: $linkColor;
}

a:hover {
	color: $linkHoverColor;
}

a:active {
	color: $linkActiveColor;
}

.hidden-truth>*>span {
	background-color: $darkTextColor;
	cursor: pointer;
}

.hidden-truth a {
	color: $darkTextColor;
}

.truth:not(.hidden-truth)>*>span {
	transition: background-color .5s linear;
}

footer {
	background-color: $footerBackgroundColor;
	bottom: 0px;
	color: $lightTextColor;
	height: var(--footer-height);
	left: 0;
	padding: var(--footer-padding);
	position: absolute;
	right: 0;
	text-align: center;
}